import * as React from 'react';
import flagLatamSrc from 'images/custom-flags/latam.svg?url';
import flagAuSrc from 'images/flags/4x3/au.svg?url';
import flagCaSrc from 'images/flags/4x3/ca.svg?url';
import flagNlSrc from 'images/flags/4x3/nl.svg?url';
import flagNzSrc from 'images/flags/4x3/nz.svg?url';
import flagUsSrc from 'images/flags/4x3/us.svg?url';
import { type RegionId } from 'models/report';

export const Flag = ({
    regionId,
    ...props
}: { regionId: RegionId; title: string } & React.DetailedHTMLProps<
    React.ImgHTMLAttributes<HTMLImageElement>,
    HTMLImageElement
>): React.ReactElement => {
    const flagSrc = React.useMemo(() => {
        switch (regionId) {
            case 'au':
                return flagAuSrc;
            case 'ca':
                return flagCaSrc;
            case 'latam':
                return flagLatamSrc;
            case 'nl':
                return flagNlSrc;
            case 'nz':
                return flagNzSrc;
            case 'us':
                return flagUsSrc;
        }
    }, [regionId]);

    return <img src={flagSrc} alt={props.title} {...props} />;
};
